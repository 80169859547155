frappe.ready(function () {
	if (frappe.session.user === "Guest" && window.show_language_picker) {
		let dropdown_buttons = document.querySelectorAll("#languageDropdown");
		let en_flage = "/assets/system_experts_website/images/svgs/en.svg";
		let ar_flage = "/assets/system_experts_website/images/svgs/ar.svg";

		// Show Langugae Picker
		let language_dropdown_container = document.querySelectorAll(".language-dropdown");
		for(const container of language_dropdown_container) {
			container.classList.remove("hide");
		}

		let language_dropdown_items = document.querySelectorAll(".dropdown-item");

		language_dropdown_items.forEach(item => {
			item.addEventListener('click', function() {
				let selected_language = this.getAttribute("data-value");
				let selected_flag = this.querySelector("img").src;
				let selected_text = this.textContent.trim();

				// Update all dropdown buttons with the selected language and flag
				let dropdown_buttons = document.querySelectorAll("#languageDropdown");
				dropdown_buttons.forEach(button => button.innerHTML = `<img src="${selected_flag}" alt="${selected_text}"> ${selected_text}`);

				// Save the selected language in cookies and localStorage
				document.cookie = `preferred_language=${selected_language}; path=/`;
				localStorage.setItem('preferred_language', selected_language);

				// Reload the page to apply the selected language
				window.location.reload();
			});
		});

		// Load the preferred language from cookies or localStorage (if exists)
		if(!get_cookie("preferred_language")) document.cookie = `preferred_language=en; path=/`;

		let preferred_language = get_cookie("preferred_language") || localStorage.getItem('preferred_language');
		let flag_src = preferred_language === 'en' ? en_flage : ar_flage;
		let language_name = preferred_language === 'en' ? 'English' : 'Arabic';

		// Set all dropdown buttons based on the preferred language
		dropdown_buttons.forEach(button => button.innerHTML = `<img src="${flag_src}" alt="${language_name}"> ${language_name}`);

		// Helper function to get the value of a cookie by name
		function get_cookie(name) {
			let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
			if (match) return match[2];
			return null;
		}
	}
});